<template>
  <div>
    <checkMaxCalendars :id="settings.ID">
      <div class="flix-alert flix-alert-warning" v-if="sameName">
        {{ $t('message.duplicateCalendarName') }}
      </div>
      <flix-quickstart
        :key="getKey()"
        :user="settings.user"
        :calendar="settings.ID"
        :lang="$i18n.locale"
        @onchanged="
          (data) => {
            changeData(data)
          }
        "
        @onsave="
          (data) => {
            saveData(data)
          }
        "
      />
    </checkMaxCalendars>
  </div>
</template>
<script>
export default {
  components: {
    checkMaxCalendars: () => import('./checkMaxCalendars')
  },
  props: { data: Object, services: Array },
  data() {
    return {
      settings: this.getSettings(),
      sameName: false
    }
  },
  computed: {},
  mounted() {},
  beforeDestroy() {
    this.$store.state.assistents.assistents = []
    this.$store.state.assistents.assistentsByID = {}
    this.$store.commit('cleanAssistents')

    this.$store.dispatch('getAssistents', () => {})
  },
  methods: {
    async saveData(data) {
      data.detail[0].assistent = JSON.parse(
        JSON.stringify(data.detail[0].assistent)
      )

      await this.changeData(data)
      if (typeof this.settings.ID === 'undefined') {
        await new Promise((resolve) => {
          setTimeout(resolve, 3000)
        })

        this.$router.push({
          name: 'dashboardAssistent',
          params: { id: 'all' }
        })
      }
    },
    checkDuplicateName(data) {
      this.sameName = false

      if (
        typeof this.$store.getters.assistents !== 'object' ||
        !this.$store.getters.assistents.length
      ) {
        return false
      }

      if (typeof data.title === 'undefined' || !data.title) {
        return false
      }

      let count = 0
      this.$store.getters.assistents.forEach((a) => {
        if (a.title.trim() === data.title.trim()) {
          count++
        }
      })
      if (count > 1) {
        this.sameName = true
      }
    },
    async changeData(data) {
      this.checkDuplicateName(data.detail[0].assistent)
      if (typeof this.settings.ID !== 'undefined' && this.settings.ID) {
        await this.saveDataEdit(data)
      }
    },
    async saveDataEdit(data) {
      if (Object.keys(data.detail[0].service).length) {
        this.setService(data.detail[0].service, data.detail[0].assistent)

        const services = JSON.parse(JSON.stringify(data.detail[0].service))
        Object.keys(services).forEach(function (k) {
          services[k].short = JSON.stringify(services[k].short)
          delete services[k].ID
        })

        this.$flix_post({
          url: '/assistent/save_services',
          data: {
            user: this.data.user,
            data: services
          },
          callback: () => {}
        })
      }

      this.setSave(data.detail[0].assistent)

      const formdata2 = new FormData()
      formdata2.append('user', this.data.user)
      formdata2.append('ID', this.data.ID)
      formdata2.append('data', JSON.stringify(data.detail[0].assistent))

      await fetch(
        process.env.VUE_APP_API_URL +
          '/' +
          this.$i18n.locale +
          '/assistent/save',
        {
          method: 'POST',
          body: formdata2
        }
      )
    },
    getKey() {
      var key = this.$i18n.locale
      if (
        typeof this.data !== 'undefined' &&
        typeof this.data.ID !== 'undefined' &&
        this.data.ID
      ) {
        key += this.data.ID
      }
      return key
    },
    getSettings() {
      var sett = { user: this.data.user, api_url: process.env.VUE_APP_API_URL }
      if (
        typeof this.data === 'undefined' ||
        typeof this.data.ID === 'undefined' ||
        !this.data.ID
      ) {
        return sett
      }
      return Object.assign(sett, { ID: this.data.ID + '' })
    },
    setService(data, assistent) {
      assistent.ID = this.getAssistentID(assistent)
      if (!assistent.ID) {
        return false
      }
      if (typeof data !== 'object' || !Object.keys(data).length) {
        return false
      }
      this.$store.commit('setServices', data)
    },
    getAssistentID(data) {
      if (
        typeof this.data !== 'undefined' &&
        typeof this.data.ID !== 'undefined' &&
        this.data.ID
      ) {
        data.ID = this.data.ID
      }
      if (typeof data.ID === 'undefined') {
        return false
      }
      return data.ID
    },
    setSave(data) {
      data.ID = this.getAssistentID(data)
      if (!data.ID) {
        return false
      }
      data.color = data.styling.accent

      var insert = {
        ID: data.ID,
        color: data.color,
        date: '',
        title: data.title,
        webinar: data.webinar
      }

      var n = JSON.parse(
        JSON.stringify(this.$store.state.assistents.assistents)
      )
      var existing = false
      Object.keys(n).forEach(function (key) {
        if (n[key].ID && data.ID && n[key].ID + '' === data.ID + '') {
          n[key] = insert
          existing = true
        }
      })

      if (!existing) {
        n.push(insert)
      }

      this.$store.commit('setAssistents', n)
      this.$store.commit('setAssistentColor', data)
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@/components/assets/glyphicons/glyphicon.scss"
</style>
